import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Chivay",
    taxonomy: "category",
    count: 3635,
    thumbnail:
      "https://terandes.com/wp-content/uploads/2023/01/Chivay-Arequipa.webp",
  },
  {
    id: "2",
    href: "#",
    name: "Yanque",
    taxonomy: "category",
    count: 3417,
    thumbnail:
      "https://www.tierra-inca.com/album/photos/017/01711.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Achoma",
    taxonomy: "category",
    count: 3450,
    thumbnail:
      "https://seturismo.pe/wp-content/uploads/2019/03/Mirador-achomami-Achoma-arequipa.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Maca",
    taxonomy: "category",
    count: 3262,
    thumbnail:
      "https://andeangreattreks.com/wp-content/uploads/CAN%CC%83ON-DEL-COLCA-BY-ANDEAN-GREAT-TREKS.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Cabanaconde",
    taxonomy: "category",
    count: 3287 ,
    thumbnail:
      "https://previews.123rf.com/images/mathess/mathess1607/mathess160701136/59374776-profundo-ca%C3%B1%C3%B3n-del-colca-detr%C3%A1s-del-pueblo-de-cabanaconde-per%C3%BA.jpg",
  },
  {
    id: "2",
    href: "#",
    name: "Cruz del Condor",
    taxonomy: "category",
    count: 3800 ,
    thumbnail:
      "https://c8.alamy.com/zoomses/9/f8d1ef8c77f84592ac27c1510e253d95/f7kpak.jpg",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Ivan Peru Tours</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-20 pb-16" />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />
        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        {/* SECTION */}
    {/*     <SectionHowItWork /> */}

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
       {/*  <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION */}
{/*         <SectionVideos /> */}

        {/* SECTION */}
{/*         <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div> */}
      </div>
    </div>
  );
}

export default PageHome;
