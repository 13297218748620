import React, { FC , useRef, useState} from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import emailjs from '@emailjs/browser';
import PropertyTypeSelect from "components/HeroSearchForm/PropertyTypeSelect";

export interface PageContactProps {
  className?: string;

}

const info = [
  {
    title: "🗺 DIRECCIÓN",
    desc: "Arequipa-Perú",
  },
  {
    title: "💌 EMAIL",
    desc: "ivanperutours@gmail.com",
  },
  {
    title: "☎ TELÉFONO",
    desc: "+51 987323958",
  },
];
 

const PageContact: FC<PageContactProps> = ({ className = ""  }) => {
  const form = useRef(null)
  const [show, setShow] = useState(true)
  const sendEmail = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

  emailjs.sendForm('service_hw957dq', 'template_lssmzbt', form.current!, 'ZK6TyPCJ2VSeHXUxl')
    .then((result) => {
        console.log(result.text);
   
    }, (error) => {
        console.log(error.text);
    });
  
  
  };
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Ivan Peru Tours</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contáctenos
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 REDES SOCIALES
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" ref={form} onSubmit={sendEmail} method="post">
                <label className="block">
                  <Label>Nombbre Completo</Label>

                  <Input
                    placeholder="Tus nombres"
                    type="text"
                    className="mt-1"
                    name="user_name"
                  />
                </label>
                <label className="block">
                  <Label>E-mail</Label>

                  <Input
                    type="email"
                    placeholder="tuCorreo@mail.com"
                    className="mt-1"
                    name="user_email"
                  />
                </label>
                <label className="block">
                  <Label>Comentanos que necesitas</Label>
                  <Textarea className="mt-1" rows={6} name = "message" />
                </label>
          
                <div>
                  <ButtonPrimary type="submit">Enviar Mensaje</ButtonPrimary>
               
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
     {/*  <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
