import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const PHOTOS: string[] = [
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/366653954_674758121344897_6030513615961752477_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=49d041&_nc_eui2=AeFIH0cFYUxyOKnlCyufCUKcP6MT4kwVjfA_oxPiTBWN8PdU1RAuf6W2aHNvS7Ht2ub2AyDqBH6_y0azqZg4Qrp7&_nc_ohc=BcMgu--__nEAX_0EkzV&_nc_ht=scontent.faqp3-1.fna&oh=00_AfDIhKLylUqbJEM4mst3liFa07TadZNQ8KgSqSrWD-PXRw&oe=652504F0",
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/353825085_638727944947915_4636876014627586171_n.jpg?stp=c0.132.1200.1200a_dst-jpg_s552x414&_nc_cat=111&ccb=1-7&_nc_sid=0df3a7&_nc_eui2=AeHfugUnRX35m-1jopV6r3XmldykxJj0D-eV3KTEmPQP58bumWMkIgCLtRLiEDZYof3xge6a5j5nrlVnIYgdIVfE&_nc_ohc=z8ndNOJtPtcAX-ie4Ss&_nc_ht=scontent.faqp3-1.fna&oh=00_AfCRlpcH-q1ZxMFj2UWdWBC2bLYGKrEJszykrvDpc7JM3Q&oe=65260D6C",
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/341014065_2284556401731030_1464361940980384890_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=49d041&_nc_eui2=AeFQuvSnueI_cWcBxlPXKwuHv7wofRckszi_vCh9FySzODHa00mCzJmX7Pa_6rwBOfmnYAePZgbVL76fGmvPnWvU&_nc_ohc=GlSfWi28IHkAX8kL9Qk&_nc_ht=scontent.faqp3-1.fna&oh=00_AfD1Spp-6MqM6kT3sQZOYAsUOzLwh8DXx1JICAQym2jrmA&oe=652589A9",
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/340946312_169359416038638_2461344861128063025_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=49d041&_nc_eui2=AeGbCoZnykXHykSv0kXeoUL_u5OExvzq3je7k4TG_OreN8U7pKcAVatbvN5JQhzz6hZIO9Nl7Guv2PJ7qhtQdyz7&_nc_ohc=BTdeq8Lvk-wAX8QuYMk&_nc_ht=scontent.faqp3-1.fna&oh=00_AfAFxRPe8enFkXyiVz9bIxoegJ01JA9f4t_RM0dQ-0cxPQ&oe=65251C2A",
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/312522582_490598656427512_1965314448235731307_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=49d041&_nc_eui2=AeHsUsUrd_Sb_CtTr9PvvnRSZ4DmtmEqnR9ngOa2YSqdH3jfoC8sa1ukG-ICqY-3Zbuv_jb40QzKIh2WuRO4MoMT&_nc_ohc=4momQThbZtsAX9Jvo38&_nc_ht=scontent.faqp3-1.fna&oh=00_AfD2TVy_ZWX3jf_gnYchBZ18kqKXWV1eFwImSXSiYNMV7Q&oe=6524F48A",
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/308542320_462236682597043_818477268917115617_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=49d041&_nc_eui2=AeGMK9AVIFx5s-QLmT9vGfIAd7zf_HiNJch3vN_8eI0lyHKampL3yuwkEp8OS1uqy1DDoMxiN2t-U4eDwQzKZJtX&_nc_ohc=oFqtfhrnR9MAX9TSnOg&_nc_ht=scontent.faqp3-1.fna&oh=00_AfAf8I0edNAIN5laW4_gH7Opz3FhoO-45hJON9sZt1dAsg&oe=6526132E",
  "https://scontent.faqp3-1.fna.fbcdn.net/v/t39.30808-6/306570319_452494596904585_1861055569824137737_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=49d041&_nc_eui2=AeEQ4Q4XdtBVxXlWX5UFcMyP_RGfWBd_nkj9EZ9YF3-eSFGeWWtAcnTBQwDHhecWgXYaMSETPPNTUXe_fSCURmZF&_nc_ohc=lNDVDRm6g0gAX-jjKPh&_nc_ht=scontent.faqp3-1.fna&oh=00_AfD82AO2lB9-ujtee0ibHUnSaoHMSRzGrMfNL6X6KgPfyw&oe=6525F2F6"
];

const Amenities_demos = [
  { name: "Transfer: entrada / salida", icon: "la-key" },
  { name: "Vehículo turístico", icon: "la-car" },
  { name: "Boleto turístico para el Cañón del Colca.", icon: "la-ticket-alt" },
  { name: "Entrada a los naños termales", icon: "la-swimmer" },
  { name: "Guía profesional", icon: "la-people-carry" },
  { name: "Todas las comidas durante el tiempo en el cañón (03 desayunos, 03 almuerzos, 02 cenas.", icon: "la-utensils" },
  { name: "02 noches de alojamiento", icon: "la-bed" },
];

const ArrayNoIncluye = [
  { name: "Agua", icon: "la-water" },
  { name: "Snacks", icon: "la-cannabis" },
];


const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(4, "days"),
  });

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name="Confia en nosotros" />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Trekking Colca Canyon 3d/2n
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Perú, Arequipa, Caylloma - Valle del Colca</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Ivan Peru Tours
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              1 to n <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
               <span className="hidden sm:inline-block">hoteles</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">servicios a pedido</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              <span className="hidden sm:inline-block">información</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-3xl font-semibold">Itinerario</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <h2 className="text-2xl font-semibold">Día 1: Arequipa – San Juan de Chuccho </h2>
          <br />
          <span>
          03:00 am Lo recogeremos  para iniciar el viaje fuera de Arequipa. Viajarás por 3 horas 
          a la ciudad de Chivay, donde tomarás desayuno. Luego, visitarás el mirador Cruz del Cóndor,
          a 3700 m de altitud, donde permaneceremos alrededor de 40 minutos para recorrer el lugar, 
          ver cóndores y sacar fotografías. Posteriormente, continuarás el camino en vehículo hasta 
          San Miguel, a 3300 m de altitud, donde tendrás unos minutos para organizar tu mochila y
          comenzar la caminata. Iniciarás el descenso por el cañón. Después de 3 horas de descenso, 
          cruzarás el puente para ir hasta la comunidad de San Juan de Chuccho, a 2300 m de altitud, 
          donde tendrás el almuerzo en casa de una familia local. Aquí aprovecharás el resto de la 
          tarde para descansar y aprender sobre las costumbres de las familias que habitan el cañón del Colca.
          </span>
          <br />
          <br />
          <h2 className="text-2xl font-semibold">Día 2: Oasis de Sangalle</h2>
          <br />
          <span>
          Después del desayuno y de descansar un momento, caminarás por 3 horas, pasando por Cosñirhua y Malata, 
          misteriosos pueblos de montaña con algunas construcciones que podrás ver desde el camino. 
          Llegarás hasta el Oasis de Sangalle, a 2200 m de altitud, donde podrás disfrutar la naturaleza, 
          la piscina y descansar.
          </span>
          <br /> <br />
          <h2 className="text-2xl font-semibold">Día 3: Cabanaconde – Chivay – Arequipa </h2>
          <br />
          <span>
          04:00 am Empezaremos nuestro trekking de ascenso por un período de 3 a 4 horas, hasta la parte 
          más alta del cañón. En el camino, podrás ver el amanecer en la medida que avanzas el trekking. 
          Verás el cambio con las primeras luces del alba y cómo se ilumina todo el cañón. 
          En la parte más alta, tendrás un tiempo para descansar y observar la hazaña que acabas de completar. 
          Luego, caminarás un poco más hasta Cabanaconde, a 3300 m de altitud, donde podrás tomar desayuno.
          Después, tomarás el transporte para pasar por los miradores de Antahuilque, desde donde podrás ver
          las terrazas de cultivo y los baños termales, donde podrás relajarte y descansar también del trekking. 
          Posteriormente visitaremos las aguas termomedicinales del pueblo de Yanque, luego continuaremos a Chivay 
          para el almuerzo, después de lo que comenzará el viaje de retorno, parando en el mirador de volcanes a 
          4910 m de altitud, antes de llegar a la ciudad de Arequipa, donde termina la actividad.
          </span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Incluye</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Todas las caracteristicas fuera de esta relación no estan incluidas
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>
        {/* ----- */}
        
        <div className="w-14 border-b border-neutral-200"></div>
      </div>
    );
  };

  const renderSectionNoIncluye = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">No incluye</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Tenga cuidado de preveer estos detalles
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {ArrayNoIncluye.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>
        {/* ----- */}
        
        <div className="w-14 border-b border-neutral-200"></div>
      </div>
    );
  };


  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">¿Qué necesita llevar?</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Es mejor ser precavido.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Botas de trekking.</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Chaqueta impermeable / chaqueta de lluvia ( Enero – Marzo).</span>
             
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Linterna frontal.</span>
              
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Chaqueta, gorro y guantes abrigados.</span>
             
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Camiseta.</span>
            </div>
            
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Pantalones cómodos.</span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Sombrero para el sol.</span>
            </div>

            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Gafas de sol.</span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Crema de protección solar (se recomienda factor 35).</span>
            </div>

            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Recipiente de agua de plástico o metal reutilizable.</span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Agua para el primer día, pero en el camino es posible comprar botellas de agua de las familias locales.</span>
            </div>

            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Artículos de aseo.</span>
            </div>

            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Medicación personal.</span>
            </div>

            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>•	Camara y filmadoras.</span>
            </div>

          </div>
        </div>
      </div>
    );
  };

 

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Informacion sobre Ivan</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
         El mejor Guia
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}

      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Ubicación</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Caylloma - Valle del Colca (San galle)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDkcvGpet07fsoZfCP_P9WmDyBed6yP8t0",
              }}
              
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: -15.596205679867433,
                lng: -71.97637220022749,
              }}
            >
              <LocationMarker lat={-15.5962056798674337} lng={-71.97637220022749} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            Ivan Tours
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /3D-2N
            </span>
          </span>
          <StartRating />
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Únetenos</span>
         
          </div>
           {/*<div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Buena actitud</span>
            <span>0 S/.</span>
          </div> */}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Comunicate con nosotros</span>
           
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary href="/https://cart.godaddy.com/go/checkout?&isc=PEGDYGON1#/basket">Reservar</ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Mostrar todas las fotos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSectionNoIncluye()}
          {renderSection4()}
          {renderSection5()}
          {renderSection7()}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              IVAN PERU TOURS
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                
              </span>
            </span>

            <ButtonPrimary href="##">Reserve</ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingStayDetailPage;
